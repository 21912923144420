import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const IndexPage = () => {
  const data = useStaticQuery(IMAGE_DATA)
  const imageData = data?.allImageSharp?.nodes || []

  console.log("IMAGE DATA: ", imageData)

  return (
    <Layout>
      <Seo></Seo>
      <HeadingStyled>
        INVESTMENT AND <span style={{ color: "#007CC5" }}>MULTIMEDIA</span>
      </HeadingStyled>
      <SubHeadingStyled>Invest in Master Drilling</SubHeadingStyled>
      <LinkSectionTitle>
        Follow one of these links for an in-depth look
      </LinkSectionTitle>
      <LinkContainer>
        <LinkBlock to="/announcements">
          <LinkTitle>Announcements</LinkTitle>
          <ColorSheet></ColorSheet>
          <GatsbyImage
            alt="Link Image"
            className="link-image"
            image={imageData[7].gatsbyImageData}
            objectFit="cover"
            style={{
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              zIndex: "-1",
            }}
          ></GatsbyImage>
        </LinkBlock>
        <LinkBlock to="/financial-results">
          <LinkTitle>Financial Results</LinkTitle>
          <ColorSheet></ColorSheet>
          <GatsbyImage
            alt="Link Image"
            className="link-image"
            image={imageData[4].gatsbyImageData}
            objectFit="cover"
            style={{
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              zIndex: "-1",
            }}
          ></GatsbyImage>
        </LinkBlock>
        <LinkBlock to="/corporate-governance">
          <LinkTitle>Corporate Governance</LinkTitle>
          <ColorSheet></ColorSheet>
          <GatsbyImage
            alt="Link Image"
            className="link-image"
            image={imageData[5].gatsbyImageData}
            objectFit="cover"
            style={{
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              zIndex: "-1",
            }}
          ></GatsbyImage>
        </LinkBlock>
        <LinkBlock to="/">
          <LinkTitle>New Investors</LinkTitle>
          <ColorSheet></ColorSheet>
          <GatsbyImage
            alt="Link Image"
            className="link-image"
            image={imageData[2].gatsbyImageData}
            objectFit="cover"
            style={{
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              zIndex: "-1",
            }}
          ></GatsbyImage>
        </LinkBlock>
      </LinkContainer>
      <LinkContainer>
        <LinkBlock to="/integrated-reports">
          <LinkTitle>Integrated Reports</LinkTitle>
          <ColorSheet></ColorSheet>
          <GatsbyImage
            alt="Link Image"
            className="link-image"
            image={imageData[3].gatsbyImageData}
            objectFit="cover"
            style={{
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              zIndex: "-1",
            }}
          ></GatsbyImage>
        </LinkBlock>
        <LinkBlock to="/presentations">
          <LinkTitle>Presentations</LinkTitle>
          <ColorSheet></ColorSheet>
          <GatsbyImage
            alt="Link Image"
            className="link-image"
            image={imageData[1].gatsbyImageData}
            objectFit="cover"
            style={{
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              zIndex: "-1",
            }}
          ></GatsbyImage>
        </LinkBlock>
        <LinkBlock to="/sens">
          <LinkTitle>SENS</LinkTitle>
          <ColorSheet></ColorSheet>
          <GatsbyImage
            alt="Link Image"
            className="link-image"
            image={imageData[0].gatsbyImageData}
            objectFit="cover"
            style={{
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              zIndex: "-1",
            }}
          ></GatsbyImage>
        </LinkBlock>
        <LinkBlock to="/annual-general-meetings">
          <LinkTitle>Annual General Meetings</LinkTitle>
          <ColorSheet></ColorSheet>
          <GatsbyImage
            alt="Link Image"
            className="link-image"
            image={imageData[6].gatsbyImageData}
            objectFit="cover"
            style={{
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              zIndex: "-1",
            }}
          ></GatsbyImage>
        </LinkBlock>
      </LinkContainer>
    </Layout>
  )
}

export default IndexPage

// ====================
//        STYLES
// ====================

const HeadingStyled = styled.h1`
  font: normal normal bold 67px/92px Open Sans;
  color: #112952;
  width: 100%;
  display: inline-block;
  padding-left: 150px;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    padding-left: 20px;
    font: normal normal bold 51px/70px Open Sans;
  }
`
const SubHeadingStyled = styled.h4`
  font: normal normal 600 21px/28px Open Sans;
  color: #707070;
  width: 100%;
  padding-left: 150px;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    padding-left: 20px;
  }
`

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`
const LinkBlock = styled(Link)`
  width: 250px;
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 5px;
  position: relative;
  cursor: pointer;
  flex-grow: 1;
  text-decoration: none;

  &:hover {
    & > div {
      top: calc(100% - 128px);
    }
  }

  &:last-child {
    margin: 5px 0 5px 5px;
  }

  &:first-child {
    margin: 5px 5px 5px 0;
  }

  @media (max-width: ${({ theme }) => theme.desktopSmall}) {
    height: 420px;

    &:last-child {
      margin: 5px;
    }

    &:first-child {
      margin: 5px;
    }

    &:nth-of-type(odd) {
      margin: 5px 5px 5px 0;
    }

    &:nth-of-type(even) {
      margin: 5px 0 5px 5px;
    }
  }

  @media (max-width: 1050px) {
    width: 380px;
    height: 500px;
  }

  @media (max-width: 769px) {
    margin: 5px 0 !important;
  }
`

const LinkSectionTitle = styled.h2`
  width: 100%;
  text-align: center;
  font: normal normal bold 28px/38px Open Sans;
  color: #112952;
  margin: 0 20px;
  margin-bottom: 50px;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    font: normal normal bold 21px/28px Open Sans;
  }
`

const LinkTitle = styled.h3`
  font: normal normal 600 28px/38px Open Sans;
  color: white;
  margin-bottom: 45px;
  text-align: center;
  z-index: 1;
  padding: 0 10px;
`
const ColorSheet = styled.div`
  background-color: #007cc5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.9;
  transition: all 0.3s ease-out;
`

const IMAGE_DATA = graphql`
  {
    allImageSharp(
      filter: {
        fixed: {
          originalName: {
            in: [
              "title-image-announcements.png"
              "title-image-financial-results.png"
              "title-image-corporate-governance.png"
              "title-image-new-investors.png"
              "title-image-integrated-reports.png"
              "title-image-presentations.png"
              "title-image-sens.png"
              "title-image-annual-general-meetings.png"
            ]
          }
        }
      }
      sort: { fields: fixed___originalName, order: DESC }
    ) {
      nodes {
        gatsbyImageData
      }
    }
  }
`
